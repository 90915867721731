<template>
  <div>
    <b-card no-body bg-variant="dark">
      <template v-if="option_data && option_data['wdm14'] && option_data['wdm14'].general && option_data['wdm14'].general.parameters && option_data['wdm14'].general.parameters.wisdomera_modules_list">
        <template v-for="(mod, mod_ind) in option_data['wdm14'].general.parameters.wisdomera_modules_list.options">
          <b-card style="margin-bottom: 5px; padding: 5px;" no-body>
            <b-row>
              <b-col sm="12" md="8">
                <i class="fa fa-star" style="color: #50afc9;"></i> {{ mod.label }}
              </b-col>
              <b-col sm="12" md="2">
                <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.added_modules && wisdom_data.data.general.added_modules[mod.value] && wisdom_data.data.general.added_modules[mod.value].detail && wisdom_data.data.general.added_modules[mod.value].detail.user_count && wisdom_data.data.general.added_modules[mod.value].detail.user_count.val">
                  <h5 style="text-align: center;"> <i class="fa fa-user"></i> {{ mod.user_count }}</h5>
                </template>
                <template v-else>
                  <b-form-input type="number" :min="0" placeholder="Kullanıcı Sayısı" v-model="mod.user_count"></b-form-input>
                </template>
              </b-col>
              <b-col sm="12" md="2">
                <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.added_modules && wisdom_data.data.general.added_modules[mod.value]">
                  <b-button variant="danger" @click="f_deleteFromShoppingCart(mod.value)"> <i class="fa fa-trash"></i> Sepetten Çıkar</b-button>
                </template>
                <template v-else>
                  <b-button variant="success" @click="f_addToShoppingCart(mod)"> <i class="fa fa-plus"></i> Sepete Ekle</b-button>
                </template>
                <!-- <c-switch class="mx-1" color="primary" v-model="selected_modules[mod.value]" :value="1" :uncheckedValue="0" variant="3d" outline="alt" label /> -->
              </b-col>
            </b-row>
          </b-card>
        </template>
        <b-row>
          <b-col sm="12" md="6"></b-col>
          <b-col sm="12" md="6">
            <b-card no-body style="background: #50afc9; color: white; font-size: 16px;">
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> TRY (/ay) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_try_monthly && wisdom_data.data.general.price_try_monthly.val">
                    {{ wisdom_data.data.general.price_try_monthly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> TRY (/yıl) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_try_yearly && wisdom_data.data.general.price_try_yearly.val">
                    {{ wisdom_data.data.general.price_try_yearly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> USD (/ay) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_usd_monthly && wisdom_data.data.general.price_usd_monthly.val">
                    {{ wisdom_data.data.general.price_usd_monthly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> USD (/yıl) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_usd_yearly && wisdom_data.data.general.price_usd_yearly.val">
                    {{ wisdom_data.data.general.price_usd_yearly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> EUR (/ay) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_eur_monthly && wisdom_data.data.general.price_eur_monthly.val">
                    {{ wisdom_data.data.general.price_eur_monthly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="6"> <strong> EUR (/yıl) </strong> </b-col>
                <b-col sm="12" md="6">
                  <template v-if="wisdom_data && wisdom_data.data && wisdom_data.data.general && wisdom_data.data.general.price_eur_yearly && wisdom_data.data.general.price_eur_yearly.val">
                    {{ wisdom_data.data.general.price_eur_yearly.val }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue'
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { DateFunctions } from '@/services/public/date_functions';
import OptionService from '@/services/option';
import FileTransferService from '@/services/file_transfer';
import WisdomDataService from '@/services/wisdom_data';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import GlobalService from "@/services/global";
import CustomerService from "@/services/customer";
import moment from 'moment';
export default {
  name: 'Products',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect,
    Modal,
    cSwitch
  },
  props: {},
  data () {
    return {
      selected_modules: {},
      dShowTrueFalse: true,
      titck_list: [],
      d_showTitckModal: false,
      dTitckData: { 'file': '', 'date': '' },
      d_titckRows: [],
      d_titckColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "rowNumber",
        label: " "
      }, {
        key: "barcode_no",
        label: "Barkod No",
        sortable: true
      }, {
        key: "ingredient_name",
        label: "İçerik",
        sortable: true
      }, {
        key: "brand_name",
        label: "Ürün Adı",
        sortable: true
      }, {
        key: "wisdom_status",
        label: "WisdomEra",
        sortable: true
      }],
      d_selectedBrandData: '',
      wisdom_data_model_3_data: {},
      brand_list: [],
      selected_general_tab: 'ingredients',
      change: "0",
      d_selectedIngredientData: '',
      d_searchText: '',
      data_type: '',
      option_data: {},
      wisdom_data: {},
      show_wisdom_data_modal: false,
      d_searchMaterialList: [],
      d_searchMaterialColumns: [{
        key: "label",
        label: "İçerik Adı"
      }],
      wisdom_op: 'new',
      wdm_key: '',
      d_selectedBrand: '',
      d_selectedBrandKey: '',
      d_selectedBrandIndex: '',
      wisdom_data_model_3_data_key: '',
      d_showBrandData: true,
      titck_excel_doc: '',
      d_showTitckTable: true,
      d_selectedTitckInd: '',
      d_titckSearchData: { 'search': '' },
      d_userWdmrList: {},
      d_selectedWdmType: '',
      d_selectedWdmrIndex: '',
      d_selectedWdmrData: {},
      change: "0"
    };
  },
  created: function () {},
  mounted: function () {
    let query = 'data_type=wdm14&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm14'] = resp.data.result;
          this.wisdom_data = { 'data': {} };
          for (let i in this.option_data['wdm14'].param_group.param_order) {
            this.wisdom_data['data'][this.option_data['wdm14'].param_group.param_order[i]] = {};
          }
          this.$forceUpdate();
          /*
          for (let k in this.option_data['wdm14'].general.parameters['wisdomera_modules_list'].options) {

          }
          */
        } else {
          console.log('error ', resp.data.message);
        }
      });
  },
  methods: {
    f_deleteFromShoppingCart: function (wisdomera_module_value) {
      this.$delete(this.wisdom_data['data']['general'].added_modules, wisdomera_module_value);
      this.f_calculatePrice();
      this.$forceUpdate();
    },
    f_addToShoppingCart: function (wisdomera_module) {
      if (!wisdomera_module.user_count) {
        alert('Lütfen kullanıcı sayısını belirtiniz');
        return;
      }
      if (!this.wisdom_data['data']['general'].added_modules) {
        this.wisdom_data['data']['general'].added_modules = {};
      }
      this.wisdom_data['data']['general'].added_modules[wisdomera_module.value] = {
        'detail': { 'user_count': { 'val': wisdomera_module.user_count } },
        'name': wisdomera_module
      };
      this.f_calculatePrice();
      this.$forceUpdate();
    },
    f_showTrueFalse: function () {
      this.dShowTrueFalse = false;
      setTimeout(function () {
        this.dShowTrueFalse = true;
      }.bind(this), 500)
    },
    f_calculatePrice: function () {
      let data = { 'wisdom_data': this.wisdom_data };
      CustomerService.calculate_product_price(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            // console.log(resp.data.result);
            this.wisdom_data = resp.data.result;
            this.$forceUpdate();
          } else {
            msg = 'error: ' + resp.data.message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        })
    },
    f_sendToAuthority: function () {
      this.wisdom_op = 'edit';
      this.data_type = 'wdm14';
      this.d_selectedWdmrData.data.general.form_status.val = { 'label': 'Yetkiliye Gönderildi', 'value': 'sended' };
      let data = { 'data': this.d_selectedWdmrData };
      CustomerService.save_offer(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex] = resp.data.result.wdmr_key_data;
            this.d_selectedWdmrData = resp.data.result.wisdom_data;
            this.wisdom_show_update();
            msg = this.$t('wdm16.13275');
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_editWdmr () {
      this.wisdom_op = "edit";
      this.data_type = 'wdm14';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmrData));
      this.show_wisdom_data_modal = true;
    },
    wisdom_show_update: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_getWdmrData: function (wdmr_key) {
      let data = { 'wdmr_key': wdmr_key };
      CustomerService.get_wdmr_data(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmrData = resp.data.result;
          } else {
            this.d_selectedWdmrData = {};
            console.log('errorr f_getUserWdmrList : ', resp.data.message);
          }
          this.wisdom_show_update();
        });
    },
    f_selectWdmr: function (x_ind, r_ind) {
      this.d_selectedWdmType = x_ind;
      this.d_selectedWdmrIndex = r_ind;
      this.f_getWdmrData(this.d_userWdmrList[x_ind]['list'][r_ind]['key']);
    },
    f_getUserWdmrList: function () {
      let data = { 'wdm_list_type': 'selected', 'wdm_list': ['wdm14'] };
      CustomerService.get_user_wdmr_list(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_userWdmrList = resp.data.result;
            if (this.d_userWdmrList['wdm14'] && this.d_userWdmrList['wdm14']['list'].length > 0) {
              this.d_selectedWdmrIndex = 0;
              this.d_selectedWdmType = 'wdm14';
              this.f_getWdmrData(this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex]['key']);
            }
          } else {
            this.d_userWdmrList = {};
            console.log('errorr f_getUserWdmrList : ', resp.data.message);
          }
        });
    },
    add_new_wisdom_data: function (data_type) {
      this.data_type = data_type;
      this.wisdom_op = "new";
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': data_type,
        'reference_wdm': {},
        'parent': data_type
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['form_status'] = {
        'val': { 'label': 'Hazırlanıyor', 'value': 'preparing' }
      };
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    wisdomDataSave: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      this.$refs.wisdom_data_modal.calculate_name();
      let data = { 'data': this.wisdom_data };
      CustomerService.save_offer(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.show_wisdom_data_modal = false;
            if (!this.d_userWdmrList[this.data_type]) {
              this.d_userWdmrList[this.data_type] = { 'list': [], 'name': this.option_data[this.data_type].name };
            }
            if (this.wisdom_op === 'new') {
              this.d_userWdmrList[this.data_type]['list'].push(resp.data.result.wdmr_key_data);
              this.d_selectedWdmrData = resp.data.result.wisdom_data;
              this.d_selectedWdmrIndex = this.d_userWdmrList[this.data_type]['list'].length - 1;
              this.d_selectedWdmType = this.data_type;
            } else {
              this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex] = resp.data.result.wdmr_key_data;
              this.d_selectedWdmrData = resp.data.result.wisdom_data;
              this.wisdom_show_update();
            }
            msg = this.$t('wdm16.13275');
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    }
  },
  watch: {}
};

</script>

<style></style>

